<template>
  <div>
    <div class="title">
      {{ $route.meta.title }}
    </div>

    <Card :bordered="false" dis-hover class="ivu-mt">
      <!--  筛选条件-->
      <div class="condition">
        <div class="condition_t">
          <div class="condition_t_icon"></div>
          <div class="condition_t_text">
            筛选条件
          </div>
        </div>

        <div class="condition_b">
          <el-form ref="form" :model="form" label-width="70px">
            <Row :gutter="16">
              <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                <el-form-item label="表单名称">
                  <el-input v-model="form.name" placeholder="请输入表单名称"></el-input>
                </el-form-item>
              </Col>
              <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                <div class="custom">
                  <el-button class="custom-button">查询</el-button>
                  <el-button class="empty">清空</el-button>
                </div>
              </Col>
            </Row>
          </el-form>
        </div>
      </div>
    </Card>

    <Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;">
      <Row justify="space-around">
        <Col v-bind="grid_list" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">
                数据列表
              </div>
              <div class="custom" style="margin-left: 20px">
                <el-button class="custom-button">
                  <!--el-icon-devops 是自己命名的-->
                  <!--<i class="el-icon-devops" />-->
                  <span style="vertical-align: middle">批量操作</span>
                </el-button>
              </div>
            </div>
            <div>
              <el-button>导出数据</el-button>
              <el-button @click="ListSetup()">列表设置</el-button>
            </div>
          </div>
        </Col>

        <Col v-bind="grid_list" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
          <my-table
              ref="myTable"
              :columns="columns"
              :tableData="tableData"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :total="total"
              :pagination="pagination"
              @select="select"
              @currentChange="currentChange"
          >
            <!-- 不使用数据可不接收scope值 -->
            <template v-slot:operationSlot= "{ scope }">
              <el-link type="success" :underline="false" @click="FormDesign(scope.row)" v-auths="[`${$config.uniquePrefix}formdesign:list`]">设计表单</el-link>
            </template>
          </my-table>
        </Col>

      </Row>
    </Card>

    <!--列表设置弹窗-->
    <div class="dialog">
      <el-dialog
          title="列表设置"
          :visible.sync="ListSetupShow"
          width="60%"
          center
          :before-close="handleClose">
        <span>
           <PackageSortElement :AllParameters="columns"   @change="SortList" @show="dialogshow"></PackageSortElement>
        </span>
      </el-dialog>
    </div>

  </div>
</template>

<script>
// 引入自定义表格组件
import MyTable from "@/components/MyTable"
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";

import {ShowTable} from '@/utils'

export default {
  name: 'index',
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      grid: {
        xl: 6,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },

      grid_list: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24,
      },

      form:{
        name:'',
        custom:'全部',
        status:'全部',
      },

      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true
        },
        {
          label: "表单编号",
          prop: "xitongbianhao",
          isShow: true
        },
        {
          label: "表单名称",
          prop: "kehubianhao",
          isShow: true
        },
        {
          label: "操作",
          width: "200",
          slot: "operationSlot",
          isShow: true
        },
      ],
      // 表格数据
      tableData: [
        {
          xitongbianhao : "QD00000001",
          kehubianhao: "客户表",
        }
      ],
      total: 0, // 总条目数
      pagination: {
        currentPage: 1, // 当前页数
        pageSize: 10 // 每页显示条目个数
      },

      // 列表设置弹窗
      ListSetupShow:false,

      // 表单设计
      FormShow: false,

    }
  },
  // 计算属性 类似于 data 概念
  computed: {
  },
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {

  },
  // 方法集合
  methods: {
    // 当前页数改变事件
    currentChange(val) {
      this.pagination.currentPage = val
      console.log("当前页数", val)
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      console.log("选择的所有数据行", selection)
      console.log("当前选择的数据行", row)
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name"
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA"
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name"
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 仅对 type=selection 的列有效，Function(row, index) 用来决定这一行的 CheckBox 是否可以勾选
    selectable(row, index) {
      if (row.sex == "女") {
        return false
      } else {
        return true
      }
    },

    // 启用状态
    Enable(row){
      console.log(row);
      row.qiyongzhuangtai = '禁用'
    },
    Disable(row){
      console.log(row);
      row.qiyongzhuangtai = '启用'
    },

    // 点击列表设置
    ListSetup(){
      this.ListSetupShow = true;
    },
    // 列表设置关闭
    handleClose(){
      this.ListSetupShow = false;
    },
    //事件  拿到从组件内部处理的数据顺序并赋值
    SortList(value){
      // 接收子组件传递的值替换当前列表显示顺序
      this.columns = value;
    },
    dialogshow(value){
      this.ListSetupShow = value;
    },

    // 点击设计表单
    FormDesign(){
      console.log('设计表单');
      // this.FormShow = true;
      this.$router.push({
        name:'FormParser'
      })
    },


  },

  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated() {
  },
  // 离开的时候触发
  deactivated() {
  }
}
</script>

<style scoped lang="scss">
.title{
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition{
  .condition_t{
    display: flex;
    align-items: center;
  }
  .condition_t_icon{
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text{
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
</style>
